import PropTypes from 'prop-types'
import ShoppableTileSet from './partials/ShoppableTileSet'
import CATEGORIES from '@/temp-configs/category-constants'
import styles from './ProductTiles.module.scss'

const ProductTiles = ({ products }) => {
    return (
        <section className="section u-bgColor--contrast1">
            <div className={`container ${styles.shoppableTile}`}>
                <h2 className="u-marginBottom--2xl t-heading2">
                    Find the right Saatva mattress for&nbsp;you
                </h2>
                <ShoppableTileSet products={products} />
                <div className='t-color--heading u-marginTop--xl u-hidden--md-up'>
                    <a className="t-link" href={`/${CATEGORIES.MATTRESSES}`} target="_self">
                        Shop All Mattresses
                    </a>
                </div>
            </div>
        </section>
    )
}

ProductTiles.propTypes = {
    products: PropTypes.array.isRequired
}

export default ProductTiles
