import React from 'react'
import productCodes from '@/temp-configs/product-codes'
import { SvgAwardWinning } from '../Svgs'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const {
    classic,
    loom,
    contour5,
    latexHybrid,
    solaire,
    memoryFoamHybrid,
    hd
} = productCodes

const getImagesConfigByProductCodes = (codes, config) => {
    let imagesConfig = null
    codes.forEach(sku => {
        imagesConfig = {
            ...imagesConfig,
            ...{ [sku]: config[sku] }
        }
    })
    return imagesConfig
}

const imagesConfig = {
    [`${classic}`]: {
        image: {
            name: `shop-${classic}-gradient.jpg`,
            folder: `shoppable/shop-${classic}-gradient`,
            alt: `Saatva Classic Mattress`
        },
        badge: <SvgAwardWinning height={27} width={74} fill='white' />
    },
    [`${memoryFoamHybrid}`]: {
        image: {
            name: `shop-${memoryFoamHybrid}-gradient.jpg`,
            folder: `shoppable/shop-${memoryFoamHybrid}-gradient`,
            alt: `Modern Foam Mattress`
        }
    },
    [`${loom}`]: {
        image: {
            name: `shop-${loom}-gradient.jpg`,
            folder: `shoppable/shop-${loom}-gradient`,
            alt: `Saatva Loom & Leaf Mattress`
        }
    },
    [`${contour5}`]: {
        image: {
            name: `shop-${contour5}-gradient.jpg`,
            folder: `shoppable/shop-${contour5}-gradient`,
            alt: `Saatva Contour5 Mattress`
        }
    },
    [`${latexHybrid}`]: {
        image: {
            name: `shop-${latexHybrid}-gradient.jpg`,
            folder: `shoppable/shop-${latexHybrid}-gradient`,
            alt: `Saatva Latex Hybrid Mattress`
        }
    },
    [`${solaire}`]: {
        image: {
            name: `shop-${solaire}-gradient.jpg`,
            folder: `shoppable/shop-${solaire}-gradient`,
            alt: `Saatva Solaire Adjustable Mattress`
        }
    },
    [`${hd}`]: {
        image: {
            name: `shop-${hd}-gradient.jpg`,
            folder: `shoppable/shop-${hd}-gradient`,
            alt: `Saatva HD Mattress`
        }
    }
}

const homeOrder = [classic, loom, contour5, latexHybrid, memoryFoamHybrid, solaire, hd]

const config = {
    skuOrder: homeOrder,
    distributionPattern: [4, 4, 4, 4, 4, 4],
    distributionPatternTablet: [6, 6, 6, 6, 6, 6],
    numberOfTilesOnMobile: 3,
    images: getImagesConfigByProductCodes(homeOrder, imagesConfig),
    pricePrefix: 'Starting at',
    isLowestPrice: true,
    imgixProps: {
        prefixOverride: { tablet: 'mobile' },
        widths: { mobile: 300, tablet: 330, desktop: 350 },
        lazyLoad: true,
        imgixDomain: IMGIX_URL
    }
}

export default config
