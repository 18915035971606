import React from 'react'
import PropTypes from 'prop-types'

import CTA from './partials/CTA'

import data from '../../temp-configs/home-data'

import styles from './HeroSection.module.scss'
import Hero from '../Hero'

const CTAList = () => {
    const { ctas } = data

    return (
        <div className={styles.ctaList}>
            {ctas.map((cta, index) => {
                return (
                    <CTA
                        key={`cta-${cta.link}`}
                        index={index}
                        label={cta.label}
                        link={cta.link}
                        selector={cta.selector}
                    />
                )
            })}
        </div>
    )
}

CTAList.propTypes = {
    ctas: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string
        })
    ).isRequired
}

const HeroSection = () => {
    const { title, description, ctas } = data

    return (
        <Hero className={styles.homeHero}>
            <div className={styles.heroContainer}>
                <div className={styles.headingContainer}>
                    <h1 className={`t-heading1 col col--xs-12 ${styles.heading}`}>
                        {title}
                    </h1>
                </div>
                <div className="col col--xs-12 u-flexAlign--center">
                    <h3 className={`t-heading3 col--xs-12 ${styles.description}`}>
                        {description}
                    </h3>
                    <CTAList ctas={ctas} />
                </div>
            </div>
        </Hero>
    )
}

export default HeroSection
