import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { ShoppableTile as ShoppableTileBit } from '@saatva-bits/pattern-library.components.shoppable-tile'

import styles from '../ProductTiles.module.scss'

const ShoppableTile = ({
    product,
    isLowestPrice,
    colCount,
    colCountTablet,
    image,
    tag,
    pricePrefix,
    breakpoints,
    imgixProps
}) => {
    const { getDiscounts } = useGetDiscounts()

    const {
        sku,
        price: defaultPrice,
        lowestPrice,
        category,
        content
    } = product

    const formattedCategory = category.toLowerCase()
    const price = isLowestPrice ? lowestPrice : defaultPrice

    const productData = {
        sku,
        parentSku: sku,
        price,
        category: formattedCategory,
        quantity: 1,
        isPrimaryProduct: true
    }

    const { discountAmount } = getDiscounts([productData])

    const columnClasses = classNames(
        'col col--xs-12 u-cursorDefault',
        styles.shoppableTileContainer,
        { [`col--lg-${colCount}`]: colCount !== 12 }
    )

    const name = <h3 className="name" dangerouslySetInnerHTML={{ __html: content.title }} />
    const description = <h4 className="description" dangerouslySetInnerHTML={{ __html: content.subtitle }} />

    const affirmAmount = discountAmount ? (price - discountAmount) : price

    return (
        <ShoppableTileBit
            dataSelector={sku}
            className={columnClasses}
            contentClassName={styles.shoppableTileContent}
            nameContainerClassName={styles.shoppableTileName}
            financingClassName={styles.shoppableTileFinancing}
            tagContainerClassName={styles.shoppableTileTag}
            image={image}
            name={name}
            description={description}
            price={price}
            discountAmount={discountAmount || null}
            link={{
                href: `/${formattedCategory}/${sku}`,
                opensInNewWindow: false
            }}
            colCount={colCount}
            colCountTablet={colCountTablet}
            breakpoints={breakpoints}
            pricePrefix={pricePrefix}
            affirmHtmlAttributes={{
                'className': `${styles['affirm-shoppable-tile']} affirm-as-low-as`,
                'data-amount': (affirmAmount * 100),
                'data-page-type': 'category',
                'data-learnmore-show': false,
                'data-affirm-color': 'white'
            }}
            tag={tag}
            imgixProps={imgixProps}
        />
    )
}

ShoppableTile.propTypes = {
    product: PropTypes.object,
    isLowestPrice: PropTypes.bool,
    colCount: PropTypes.number,
    colCountTablet: PropTypes.number,
    image: PropTypes.object,
    pricePrefix: PropTypes.string,
    breakpoints: PropTypes.object,
    tag: PropTypes.node,
    imgixProps: PropTypes.shape({
        widths: PropTypes.object,
        prefixOverride: PropTypes.object,
        lazyLoadOffset: PropTypes.number,
        isUniversal: PropTypes.bool,
        breakpoints: PropTypes.object
    })
}

export default ShoppableTile
