import React from 'react'
import PropTypes from 'prop-types'
import styles from '../VideoSection.module.scss'

const WhyVideo = ({ video }) => {
    const videoRef = React.createRef()

    const handleClick = (e) => {
        e.preventDefault()

        const video = videoRef.current

        video.paused ? video.play() : video.pause()
    }

    return (
        <video
            className={styles.video}
            ref={videoRef}
            controls
            autoPlay
            preload="none"
            data-selector='why-saata-video'
            onClick={(e) => handleClick(e)}
        >
            <source src={video.url} type={`video/${video.type}`} />
        </video>
    )
}
WhyVideo.propTypes = {
    video: PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired
}

export default WhyVideo
