import Layout from '@/layouts/Default'

import HeroSection from '@/components/HeroSection/HeroSection'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ProductTiles from '@/components/ProductTiles/ProductTiles'
import VideoSection from '@/components/VideoSection/VideoSection'
import CategoryTiles from '@/components/CategoryTiles/CategoryTiles'
import WhySaatva from '@/components/WhySaatva/WhySaatva'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import DetailProductTileSlider from '@/components/DetailProductTileSlider'

import styles from './styles.module.scss'

export default function HomeView(props) {
    return (
        <Layout {...props}>
            <HeroSection {...props} />
            <ValuePropsSection />
            {!props.recommendedProducts.length && 
                <ProductTiles products={props.products} />
            }
            <DetailProductTileSlider 
                products={props.recommendedProducts}
                sectionClassName= {styles.sliderSection}
                titleClassName={styles.sliderTitle}
                title='Find the right Saatva mattress for you'
                dataSelector='homepage-recommended-products'
                showAffirm={true}
                hideBadge={true}
                simpleSliderProps={{
                    pageDotsAndNumbers: true,
                    isCart: true,
                    childClass: styles.sliderItem,
                    leftButtonClassName: styles.sliderLeftButton
                }}
                customImageProps={{
                    lazyLoadOffset: 0,
                }}
            />
            <VideoSection />
            <CategoryTiles />
            <WhySaatva />
            <ReviewSection reviews={props.reviewList} />
        </Layout>
    )
}
