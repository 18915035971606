import React from 'react'
import propTypes from 'prop-types'
import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'

const PictureImgix = (props) => (
    <Picture imgixDomain={process.env.NEXT_PUBLIC_IMGIX_URL} {...props} />
)


PictureImgix.propTypes = {
    ...Picture.propTypes,
    imgixDomain: propTypes.string
}

export default PictureImgix
