import React from 'react'
import styles from './CategoryTiles.module.scss'
import PictureImgix from '../PictureImgix/PictureImgix'

// TODO: need to redirect to the appropriate url for the corresponding environment for coresite-node.
const tiles = [
    {
        imageName: 'foundation-category-v1.jpg',
        imageAlt: 'Foundation & Base Image Tile',
        href: '/furniture?types=adjustable+bases%2Cmattress+foundation',
        name: `Foundation & Base`
    },
    {
        imageName: 'bed-frames-category-v1.jpg',
        imageAlt: 'Bed Frames Image Tile',
        href: '/furniture?types=bed+frames',
        name: 'Bed Frames'
    },
    {
        imageName: 'pad-toppers-category-v1.jpg',
        imageAlt: 'Mattress Pad & Toppers Image Tile',
        href: '/bedding?types=mattress+pad+and+toppers',
        name: `Mattress Pad & Toppers`
    },
    {
        imageName: 'pillows-category-v1.jpg',
        imageAlt: 'Pillows Image Tile',
        href: '/bedding?types=pillows+type',
        name: 'Pillows'
    },
    {
        imageName: 'bed-sheets-category-v1.jpg',
        imageAlt: 'Bed Sheets Image Tile',
        href: '/bedding?types=sheets+type',
        name: 'Bed Sheets'
    },
    {
        imageName: 'duvet-comforters-category-v1.jpg',
        imageAlt: 'Duvet Covers & Comforters Image Tile',
        href: '/bedding?types=duvet+covers%2Ccomforters',
        name: `Duvet Covers & Comforters`
    }
]

const CategoryTiles = () => {
    return (
        <section className="section categoryTiles">
            <div className="container u-flexJustify--center">
                <h2 className={styles.title}>Create your perfect sleep sanctuary</h2>
                <div className="row">
                    {
                        tiles.map(({ imageName, imageAlt, href, name }) => (
                            <div key={imageName} className="col col--xs-6 col--sm-6 col--md-4">
                                <a href={href} className={styles.tile} data-selector={`category-tile-cta-${name}`}>
                                    <PictureImgix
                                        imageClassName={styles.image}
                                        folder="homepage/category-tiles"
                                        name={imageName}
                                        alt={imageAlt}
                                        lazyLoadOffset={100}
                                        widths={{
                                            mobile: 160,
                                            tablet: 220,
                                            desktop: 360
                                        }}
                                        isUniversal
                                    />
                                    <h3 className={styles.name}>{name}</h3>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default CategoryTiles
