import React from 'react'
import PropTypes from 'prop-types'

import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'

import config from './shoppableTileConfig'
import productCodes from '@/temp-configs/product-codes'

import ShoppableTile from './ShoppableTile'

const ShoppableTileSet = ({ products }) => {
    const { isMobile } = useBreakpoints('desktop')

    const {
        skuOrder,
        distributionPattern,
        images,
        pricePrefix,
        isLowestPrice,
        distributionPatternTablet,
        numberOfTilesOnMobile,
        imgixProps
    } = config

    const productsOrder = skuOrder.filter(productCode => productCode !== productCodes.loom)

    // sort product tiles based on config
    const productsToSortBy = numberOfTilesOnMobile && isMobile ? productsOrder.slice(0, numberOfTilesOnMobile) : productsOrder

    const shoppableTiles = products
        .filter(({ productCode }) => productsToSortBy.includes(productCode))
        .sort((a, b) => productsToSortBy.indexOf(a.productCode) - productsToSortBy.indexOf(b.productCode))
        .map((product, i) => {
            const productCode = product.productCode
            return (
                <ShoppableTile
                    key={`shoppableTile-${i}`}
                    product={{...product, sku: productCode}}
                    isLowestPrice={isLowestPrice}
                    isHomePage={true}
                    colCount={distributionPattern[i] || 12}
                    colCountTablet={ distributionPatternTablet && distributionPatternTablet[i]}
                    image={images[productCode].image}
                    tag={images[productCode].badge}
                    pricePrefix={pricePrefix}
                    breakpoints={images[productCode].breakpoints}
                    imgixProps={imgixProps}
                />
            )
        })

    return <div className="row u-flexJustify--center">{shoppableTiles}</div>
}

ShoppableTileSet.propTypes = {
    products: PropTypes.array
}

export default ShoppableTileSet
