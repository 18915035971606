// Including just the data we know is currently required and can add to this if more is needed
const homeData = {
    title: 'Smarter Luxury Sleep',
    description: 'The highest quality handcrafted mattresses, bedding & furniture at the most comfortable prices.',
    ctas: [
        {
            label: 'Shop all mattresses',
            link: '/mattresses',
            selector: 'homeHeroShopAllLink'
        },
        {
            label: 'Take our mattress quiz',
            link: '/mattress-quiz',
            selector: 'homeHeroQuizLink'
        }
    ]
}

export default homeData
