import React from 'react'
import classNames from 'classnames'

import styles from '../HeroSection.module.scss'

/**
 * Only shows the first CTA on mobile, on tablet onwards shows all
 */
const CTA = ({ index, label, link, selector }) => {
    /**
     * Add the hide class utility to all ctas after first one
     */
    const dividerClasses = classNames(styles.verticalDivider, {
        'u-hidden--md-down': index > 0
    })
    const buttonClasses = classNames(styles.button, {
        'u-hidden--md-down': index > 1
    })

    return (
        <>
            {index > 0 && <div className={dividerClasses} />}
            <a className={buttonClasses} href={link} data-selector={selector}>
                {label}
            </a>
        </>
    )
}

export default CTA
