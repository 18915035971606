// determine if the element is either above or below the viewport
const isInViewport = (el, offset = 0) => {
    const { top, bottom } = el.getBoundingClientRect()
    const vHeight = (window.innerHeight || document.documentElement.clientHeight) + offset

    return (
        (top > 0 || bottom > 0) &&
        top < vHeight
    )
}

const getDocumentOffset = (el) => {
    let elementToCheck = el
    let distance = 0

    // travel up DOM to get distance between child and parents to get total offset from top of document
    if (elementToCheck.offsetParent) {
        do {
            distance += elementToCheck.offsetTop
            elementToCheck = elementToCheck.offsetParent
        } while (elementToCheck)
    }
    return distance < 0 ? 0 : distance
}

export {
    isInViewport,
    getDocumentOffset
}
