import React, { useRef, useState, useEffect } from 'react'
import styles from './VideoSection.module.scss'
import { isInViewport, getDocumentOffset } from '@/utils/position'
import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { VideoImgix } from '@saatva-bits/pattern-library.components.video-imgix'
import WhyVideo from './partials/WhyVideo'
import { pushDataLayerEvent } from '@/utils/datalayer'

const video = {
    type: 'mp4',
    url: 'https://saatva.com/videos/why-saatva.mp4'
}

const VideoSection = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isPlaying, setIsPlaying] = useState()
    const ref = useRef(null)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = () => {
        if (isInViewport(ref.current)) {
            const scrollableArea = ref.current.offsetHeight + window.innerHeight
            const containerBottom = getDocumentOffset(ref.current) + ref.current.offsetHeight
            const distanceScrolled = window.pageYOffset
            ref.current.style.backgroundPosition = `center ${(((containerBottom - distanceScrolled) / scrollableArea) * 100).toFixed(2)}%`
        }
    }

    const onModalClose = () => {
        pushDataLayerEvent({
            'event': 'closeWhySaatvaVideo'
        })
        setIsPlaying(true)
        setIsModalVisible(false)
    }

    return (
        <section className={styles.container}>
            <VideoImgix
                aspectRatio={'16-9'}
                className={styles.backgroundVideo}
                dataSelector='why-saatva-video'
                extension='mp4'
                filename='why-saatva'
                folder='videos'
                type='video/mp4'
                parentPlayState={isPlaying}
                showComponentControls={false}
                imgixSubdomain={process.env.NEXT_PUBLIC_IMGIX_VIDEO_URL}
                imgixOptions={{
                    fm: 'mp4'
                }}
                videoProps={{
                    autoPlay: true,
                    muted: true,
                    loop: true,
                    playsInline: true,
                    preload: 'auto', // needs to be auto for Safari
                }}
                lazyLoadOffset={500}
            />
            <button
                ref={ref}
                className={styles.playButton}
                data-selector='why-saatva-video-btn'
                onClick={() => {setIsModalVisible(true); setIsPlaying(false)}}
            >
                <h2 className={styles.text}>
                    Discover the difference of Smarter Luxury Sleep
                    <div className={styles.playVideoCTA}>
                        Play Video <span className={styles.playVideoIcon} />
                    </div>
                </h2>
            </button>
            <Modal
                type="video"
                className={styles.videoModal}
                isModalVisible={isModalVisible}
                onModalClose={onModalClose}
                modalLabel="Why Saatva video"
            >
                <WhyVideo video={video} />
            </Modal>
        </section>
    )
}

export default VideoSection
